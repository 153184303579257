export const unity = {
    setIconType(type = 'default') {
        let icontype = {
            jpg: {
                class: '#icon-tupian'
            },
            jpge: {
                class: '#icon-tupian'
            },
            png: {
                class: '#icon-tupian'
            },
            gif: {
                class: '#icon-tupian'
            },
            doc: {
                class: '#icon-Word'
            },
            docx: {
                class: '#icon-Word'
            },
            xls: {
                class: '#icon-Excel'
            },
            xlsx: {
                class: '#icon-Excel'
            },
            xlsm: {
                class: '#icon-Excel'
            },
            ppt: {
                class: '#icon-PowerPoint'
            },
            pptx: {
                class: '#icon-PowerPoint'
            },
            pdf: {
                class: '#icon-pdf'
            },
            txt: {
                class: '#icon-txt'
            },
            folder: {
                class: '#icon-wenjianjia'
            },
            folderEmpty: {
                class: '#icon--folder-empty'
            },
            file: {
                class: '#icon-wenjian'
            },
        }
        return icontype[type]
    },
}

export const fileTypes = [
    'jpg',
    'jpge',
    'png',
    'gif',
    'doc',
    'docx',
    'xls',
    'xlsm',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'txt', 
    // 'folder',
]